<script>
  import { fly } from "svelte/transition";
</script>

<style>
  footer {
    min-height: 80px;
    background-color: #000000;
  }
</style>

<footer class="flex flex-column md:flex-row items-center justify-evenly">
  <p class="text-white py-2">2019 cracss</p>
  <p class="text-white py-2">Río de Janeiro 1725 Torre 1 Of. 5</p>
  <p class="text-white py-2">5105, Villa Allende, Argentina.</p>
</footer>
